<template>
  <div class="app_con">
    <app-list
       ref="applist"
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :rules="rules"
      :editTitle="'用户'"
      :isInline="true"
    
    >
    <div slot="toolSub" selecTionArr="">
  
        <el-button
          size="small"
          type="primary"
          icon="el-icon-user"
          @click="batchSetUser"
          >批量配置用户</el-button
        >
      </div>
      <div class="search" slot="tool">
        <el-input
          placeholder="根据名称查询"
          v-model="searchQuery.siteName"
          class="searchInput"
          size="small"
        >
          <template slot="prepend">名称</template>
        </el-input>

        <el-input
          placeholder="根据电报码查询"
          v-model="searchQuery.siteCode"
          class="searchInput"
          size="small"
        >
          <template slot="prepend">电报码</template>
        </el-input>
      </div>
    </app-list>
    <el-dialog
      :title="isBatch ? '批量配置站点' : '配置单个站点'"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
    >
      <div class="uesrCon">
        <el-table
          :data="userList"
          height="290"
          ref="multipleTable"
          border
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>

          <el-table-column prop="name" label="姓名" width="180">
          </el-table-column>
          <el-table-column prop="departmentName" label="部门">
          </el-table-column>
          <el-table-column prop="payAccountName" label="支付宝">
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    let self = this;
    return {
      pswDialogVisible: false,
      remote: api.common,
      apiName: "/systemSite",
      dialogVisible: false,
      userList: [],
      isBatch: false,
      searchVal: "",
      bntLoading: false,
      appListData:[],
      form: {
        id: 0,
        siteName: "",
        siteCode: "",
        userIdList: [],
        apiName:'/systemSite/update'
      },
      searchControl: {
        firstSoltSpan: [5, 5, 5, 5],
        secondSoltSpan: [3, 3, 3, 3],
        thirdSoltSpan: [14, 14, 14, 14],
      },
      searchQuery: { siteName: "", siteCode: "" },
      rules: {
        //传递给APPlist的表单验证

        siteName: [
          {
            required: true,
            message: "请输入站点名称",
            trigger: ["blur", "change"],
          },
        ],
        siteCode: [
          {
            required: true,
            message: "请输入电报码",
            trigger: ["blur", "change"],
          },
        ],
      },
      props: [
        {
          label: "站点名称",
          prop: "siteName",
          align: "center",
        },

        {
          label: "电报码",
          prop: "siteCode",
          align: "center",
        },

        {
          label: "操作",
          prop: "qrcode",
          align: "center",
          width: 250,
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                text: "用户",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-user",
                type: "success",

                handler: function () {
                  self.handleUsers(row.id);
                },
              },
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "update",
                handler: function () {},
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "remove",
                handler: function () {},
              },
            ];
            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "站点名称",
          prop: "siteName",
          type: "input",
        },
        {
          label: "电报码",
          prop: "siteCode",
          type: "input",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getUserList();
  },
  methods: {
    getAppListData(e) {
      this.appListData = e;
    },
    async batchSetUser() {
      this.ids = [];
      let arr = this.$refs.applist.selecTionArr;
      if (arr.length == 0) {
        this.$message.error("请先选择数据");
      } else {
                if (this.$refs.multipleTable) {
          this.$refs.multipleTable.clearSelection();
        }
        for (let index = 0; index < arr.length; index++) {
          this.ids.push(arr[index].id);
        }
        this.isBatch = true;
        this.dialogVisible = true;
      }
    },
    async getUserList() {
      let res = await api.common.commonGet({ apiName: "/user", pageSize: 100 });
      if (res.code == 200) {
        this.userList = res.data.data;
      }
    },
    handleSelectionChange(e) {
      this.form.userIdList = [];
      e.forEach((element) => {
        this.form.userIdList.push(element.id);
      });
    },
    async handleSave() {
      if (this.isBatch) {
        let obj = {
          userIdList: this.form.userIdList,
          idList: this.ids,
          apiName: "/systemSite/batchSetUset",
        };
        let res = await api.common.commonPost(obj);
        if (res.code == 200) {
          this.$message.success("批量配置成功！");
          this.isBatch = false;
          this.dialogVisible = false;
        }
      } else {
        let res = await api.common.commonPost(this.form);
        if (res.code == 200) {
          this.$message.success("配置用户成功！");
          this.dialogVisible = false;
        }
      }
    },
    async handleUsers(id) {
      let res = await api.common.commonGet({
        apiName: "/systemSite/detail",
        id: id,
      });
      if (res.code == 200) {

        console.log(res);
        this.form.id = id;
        this.form.siteName = res.data.siteName;
        this.form.siteCode = res.data.siteCode;
        this.form.userIdList = res.data.userIdList;

        let arr = [];
        res.data.userIdList.forEach((item) => {
          let uArr = this.userList.filter((v) => v.id == item);

          if (uArr.length > 0) {
            arr.push(uArr[0]);
          }
        });

        this.dialogVisible = true;

        //table回显
        this.$nextTick(function () {
          this.$refs.multipleTable.clearSelection();
          arr.forEach((row) => {
            console.log(row);
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.changeCon p {
  color: #999;
}
.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
